import React, { useEffect } from 'react'
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby";
import { UserContextProvider } from "../components/user-context";
import { getUrlOrigin } from '../utils/utils'

const onRedirectCallback = appState => {
    console.log("appState?.returnTo: ", appState?.returnTo);
    navigate(appState?.returnTo || "/", { replace: true });
};

export const wrapRootElement = ({ element }) => {

    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
            authorizationParams={{
                audience: process.env.GATSBY_AUTH0_AUDIENCE,
                redirectUri: `${getUrlOrigin()}/authorize`
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <UserContextProvider>{element}</UserContextProvider>
        </Auth0Provider>
    );
};
